/** @format */
import SEO from "../components/seo";
import { useTranslation } from "next-i18next";
import Footer from "../components/Footer";
import { getStaticProps } from "../../methods/i18n";
import { HeadScriptTag } from "../components/HeadScriptTag";

export { getStaticProps };

const Custom404 = () => {
	const { t } = useTranslation(['common', "meta_tags"]);

	const title = t("meta_tags:custom_404");
	const description = t("meta_tags:custom_404_desc");
	const keywords = "Tax,Tax Bankruptcy ,Tax Controversy";
	// const ogImage = "https://www.example.com/static/images/og-image.jpg";
	const ogType = "website";
	const canonicalURL = "https://taxworkoutgroup.com/404";

	return (
		<>
			<SEO
				title={title}
				description={description}
				keywords={keywords}
				// ogImage={ogImage}
				ogType={ogType}
				canonicalURL={canonicalURL}
			/>
      <HeadScriptTag />
        <div className="custom404">
          <h1>{t('common:page_not_found')}</h1>
        </div>
			<Footer />
		</>
	);
};

export default Custom404;
