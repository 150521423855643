/** @format */

import { Card, Col, Row, Grid } from "antd";
import axios from "axios";
import type { GetStaticPaths, NextPage } from "next";
import { useEffect, useMemo, useState } from "react";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import { convert } from "html-to-text";
import type { Article, Articles } from "../../interfaces/articles";
import { useRouter } from "next/router";
import responsiveColumn from "../../methods/responsiveColumn";
import Footer from "../components/Footer";
import CustomBreadcrumb from "../components/CustomBreadcrumb";
import { getStaticProps } from "../../methods/i18n";
import { useTranslation } from "next-i18next";
import dayjs from "dayjs";
import Custom404 from "./404";
import SEO from "../components/seo";
import { HeadScriptTag } from "../components/HeadScriptTag";

export { getStaticProps };

export const getStaticPaths: GetStaticPaths<{ slug: string }> = async () => {
  return {
    paths: [],
    fallback: "blocking",
  };
};

const Resources: NextPage = () => {
  const [article, setArticle] = useState<Article>();
  const [articles, setArticles] = useState<Articles>();
  const router = useRouter();
  const { slug } = router.query;
  const { locale } = router;

  const setQuery = useMemo(() => {
    if (locale !== "en") {
      return `?lang=${locale}&`;
    } else {
      return "?";
    }
  }, [locale]);
  const { useBreakpoint } = Grid;
  const screenSize = useBreakpoint();
  const { t } = useTranslation("meta_tags");

  const [showCustom404, setShowCustom404] = useState<boolean>(false);

  useEffect(() => {
    fetchArticles();
  }, [locale]);

  useEffect(() => {
    if (!slug || !articles){
      setShowCustom404(true);
      return;
    }
    window.document.body.scrollTop = 0;
    const a = articles?.find((art) => art.slug === slug) ?? articles[0];
    setArticle(a);
  }, [slug, articles]);

  const fetchArticles = async () => {
    try {
      const r = await axios({
        url: `https://wp.taxworkoutgroup.com/wp-json/wp/v2/pages${setQuery}_embed`,
        method: "get",
      });
      setArticles(r.data);
    } catch (e) {
      console.log(e);
    }
  };

  const title = article ? `${article.title.rendered} | Tax Workout Group` : t("home");
	const description =	t("home_desc");
	const keywords = "Tax,Tax Bankruptcy ,Tax Controversy";
	// const ogImage = "https://www.example.com/static/images/og-image.jpg";
	const ogType = "website";
	const canonicalURL = article ? `https://taxworkoutgroup.com/${slug}` : "https://taxworkoutgroup.com";

  return (
    <>
    {showCustom404 ? (
      <Custom404/>
    ):(
      <>
        <SEO
          title={title}
          description={description}
          keywords={keywords}
          // ogImage={ogImage}
          ogType={ogType}
          canonicalURL={canonicalURL}
        />
        <HeadScriptTag />
        <CustomBreadcrumb
          items={[
            {
              label: "Home",
              href: "/",
            },
            {
              label: `${article?.title?.rendered ?? t("home")}`,
              href: `/${slug}`,
            },
          ]}
          />

        <Row justify={"center"} gutter={[40, 40]}>
          <Col span={24}>
            <Row>
              <Col
                lg={24}
                md={24}
                sm={24}
                style={{
                  backgroundImage: 'url("/privacy-policy.svg")',
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  height: "352px",
                  textAlign: "center",
                  width: "100%",
                }}
              >
                <div className="section_title" style={{ lineHeight: 1.5 }}>
                  <h1
                    // className="section_title"
                    style={{
                      lineHeight: 1.5,
                      paddingTop: screenSize.md ? 80 : 100,
                      color: "#FFFFFF",
                      marginBottom: 0,
                      fontSize: screenSize.md ? "80px" : "40px",
                    }}
                  >
                    {article && convert(article?.title.rendered)}
                  </h1>
                  <h6
                    style={{
                      fontSize: screenSize.md ? 20 : 16,
                      fontWeight: 600,
                      lineHeight: "32px",
                      color: "#FFFFFF",
                      opacity: "60%",
                      fontFamily: "Raleway",
                    }}
                    >
                    Last Updated:{" "}
                    {dayjs(article?.modified).format("MMMM DD YYYY")}
                  </h6>
                </div>
              </Col>
            </Row>
            <Card loading={article ? false : true}>
              <Row
                gutter={[50, 50]}
                justify="center"
                style={{ paddingTop: 30, paddingBottom: 60 }}
              >
                <Col {...responsiveColumn([16, 17, 19, 20, 22, 22])}>
                  <div
                    style={{ marginTop: 30, lineHeight: 2, fontSize: 16 }}
                    dangerouslySetInnerHTML={{
                      __html: article?.content.rendered ?? "",
                    }}
                  ></div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Footer />
      </>
      )}
    </>
  );
};

export default Resources;
